import { styled } from "@mui/system";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
import {
  Datagrid,
  DeleteButton,
  EditButton,
  FunctionField,
  ListButton,
  MenuItemLink,
  TopToolbar,
} from "react-admin";
import {
  Chip,
  Collapse,
  Grid,
  IconButton,
  MenuItem,
  Stack,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import LaunchIcon from "@mui/icons-material/Launch";
import { useRecordContext } from "react-admin";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { get } from "lodash";
import { useState } from "react";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

export const stringToColour = function (str) {
  var hash = 0;
  for (var i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  var colour = "#";
  for (var i = 0; i < 3; i++) {
    var value = (hash >> (i * 8)) & 0xff;
    colour += ("00" + value.toString(16)).substr(-2);
  }
  return colour;
};

export const StyledPaper = styled(Paper)({
  m: 8,
  p: 4,
  borderRadius: "10px",
});

export const StyledDatagrid = styled(Datagrid)({
  "& .RaDatagrid-headerCell": {
    backgroundColor: "#F5F8F1",
    fontWeight: 700,
    textTransform: "uppercase",
  },
  p: 4,
});

export const StyledMenuItemLink = styled(MenuItemLink)({
  color: "#fff",
  fontWeight: 800,
  fontSize: "1.1em",
});

export const StyledMenuItem = styled(MenuItem)({
  color: "#fff",
  fontWeight: 800,
  fontSize: "1.1em",
});

export const PaperWithTitle = (props) => {
  return (
    <Paper
      elevation={4}
      sx={[props.sx, { m: 2, borderRadius: "10px", p: 2, pl: 4 }]}
    >
      <Grid container>
        {props.title && (
          <Grid item md={8}>
            <Typography variant="h2" sx={{ p: 0, pt: 3, pb: 4 }}>
              {props.title}
            </Typography>
          </Grid>
        )}
        <Grid item md={4}>
          <TopToolbar>
            <EditButton size="medium" />
          </TopToolbar>
        </Grid>
      </Grid>
      {props.children}
    </Paper>
  );
};

export const PaperWithTitleNoPadding = (props) => {
  const [open, setOpen] = useState(!props.collapsed);
  return (
    <Paper
      elevation={4}
      sx={[props.sx, { m: 2, borderRadius: "10px", pt: 0, pb: 0 }]}
    >
      <Stack direction="row" justifyContent="space-between">
        {props.title ? (
          <Typography variant="h2" sx={{ pl: 3, pt: 3, pb: 2 }}>
            {props.title}
          </Typography>
        ) : (
          <>&nbsp;</>
        )}
        <IconButton
          onClick={() => setOpen(!open)}
          aria-label="expand"
          size="small"
        >
          {open ? (
            <KeyboardArrowDownIcon fontSize="medium" sx={{ m: 2 }} />
          ) : (
            <KeyboardArrowLeftIcon fontSize="medium" sx={{ m: 2 }} />
          )}
        </IconButton>
      </Stack>
      <Collapse in={open} timeout="auto" unmountOnExit>
        {props.children}
      </Collapse>
    </Paper>
  );
};

export const EnabledIconField = ({ source }) => {
  const record = useRecordContext();
  return record[source] ? (
    <CheckCircleIcon sx={{ color: "green" }} />
  ) : (
    <CheckCircleOutlineIcon sx={{ color: "grey" }} />
  );
};

export const CustomTextField = ({ source }) => {
  const record = useRecordContext();

  const value = get(record, source);
  return (
    <Typography variant="body2" component="span">
      {value}
    </Typography>
  );
};

export const StatoField = ({ source }) => {
  const record = useRecordContext();

  const value = get(record, source);
  return (
    <Chip
      label={value}
      sx={{ p: 1 }}
      color={
        value === "cancellato" || value == "invalido"
          ? "error"
          : value === "chiuso"
          ? "success"
          : "info"
      }
    />
  );
};

export const LinkFieldMaxLength = ({ path, maxLength }) => {
  const record = useRecordContext();
  const url = get(record, path);
  return (
    <Typography variant="body2" component="span">
      <Link to={url} color="secondary">
        {url.length > maxLength ? url.substring(0, maxLength) + ".." : url}
      </Link>
    </Typography>
  );
};

export const InternalLinkField = ({ resource, path, name }) => {
  const record = useRecordContext();
  const id = get(record, path);
  const value = get(record, name);
  const url = `/${resource}/${id}/show`;
  return (
    <Typography variant="body2">
      <Link to={url} sx={{ textDecoration: "none", color: "secondary" }}>
        {value ? (
          <>
            {value} <LaunchIcon sx={{ fontSize: "1em" }} />
          </>
        ) : (
          <ChevronRightIcon color="secondary" />
        )}
      </Link>
    </Typography>
  );
};
