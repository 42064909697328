import {
  Edit,
  SimpleForm,
  BooleanInput,
  TextInput,
  required,
  useRecordContext,
  NumberInput,
} from "react-admin";

import { PaperWithTitle } from "../layout/CustomComponents";

const WebsiteTitle = () => {
  const record = useRecordContext();
  return <span>Modifica Sito {record ? `"${record.url}"` : ""}</span>;
};

export const WebsiteEdit = () => (
  <PaperWithTitle title="Modifica Sito Gestito">
    <Edit title={<WebsiteTitle />} redirect="show">
      <SimpleForm>
        <BooleanInput source="abilitato" />
        <BooleanInput source="weekend" label="Programmazione nel weekend" />
        <TextInput source="sito" validate={[required()]} fullWidth />
        <TextInput source="url" validate={[required()]} fullWidth />
        <NumberInput source="numero_articoli" validate={[required()]} />
      </SimpleForm>
    </Edit>
  </PaperWithTitle>
);
