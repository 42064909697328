import * as React from "react";

import { useTheme } from "@mui/material/styles";

const Logo = (props) => {
  const theme = useTheme();
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="60.000000pt"
      height="80.000000pt"
      viewBox="0 0 1280.000000 975.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <metadata>
        Created by potrace 1.15, written by Peter Selinger 2001-2017
      </metadata>
      <g
        transform="translate(0.000000,975.000000) scale(0.100000,-0.100000)"
        fill="#fff"
        stroke="none"
      >
        <path
          d="M7800 9739 c-588 -39 -1097 -174 -1708 -450 -302 -137 -504 -248
-707 -391 -104 -73 -168 -124 -486 -381 -366 -296 -558 -352 -1019 -293 -322
40 -438 49 -690 49 -335 0 -530 -26 -761 -103 -211 -70 -360 -161 -509 -310
-282 -281 -436 -683 -532 -1385 -22 -161 -23 -194 -20 -646 l3 -477 -89 -118
c-240 -320 -440 -650 -632 -1039 -320 -652 -519 -1307 -600 -1970 -11 -93 -26
-208 -32 -255 -20 -139 -17 -440 6 -535 21 -89 55 -171 66 -158 5 4 47 152 95
328 146 538 222 798 319 1090 326 976 724 1696 1259 2273 l80 87 271 1 c152 0
306 6 351 13 319 47 460 79 689 155 313 105 589 249 843 438 50 37 93 68 96
68 3 0 30 -46 59 -102 80 -153 169 -290 304 -471 747 -999 1893 -1702 3277
-2013 82 -18 152 -36 155 -38 3 -3 0 -36 -6 -73 -6 -38 -59 -372 -118 -743
-59 -371 -108 -676 -109 -678 -2 -2 -65 -12 -141 -23 -595 -86 -963 -303
-1065 -630 -24 -78 -43 -228 -35 -274 l7 -36 31 43 c126 178 306 280 631 357
l117 28 -6 -186 c-8 -211 1 -358 20 -328 137 224 282 370 453 456 111 56 189
71 393 80 222 9 260 22 260 90 0 9 -28 45 -61 79 l-62 62 7 118 c21 351 102
737 212 1005 58 142 111 238 187 340 l61 80 130 -6 c454 -19 776 -272 861
-676 38 -184 58 -469 44 -639 -16 -197 -54 -313 -132 -399 -90 -98 -169 -128
-792 -298 -436 -120 -647 -244 -774 -455 -11 -19 -21 -38 -21 -41 0 -3 46 5
103 18 146 32 335 60 532 77 228 20 405 29 405 21 0 -3 -18 -75 -39 -158 -36
-140 -67 -304 -59 -312 2 -2 62 31 133 72 293 170 513 270 705 320 83 22 125
26 325 32 187 5 236 10 261 23 27 15 44 36 44 55 0 2 -37 44 -83 91 -151 157
-171 215 -172 482 0 152 4 205 23 295 79 392 288 745 670 1132 153 154 245
231 569 475 147 111 309 235 358 277 583 491 845 1004 970 1899 140 1006 82
1734 -187 2362 -179 419 -528 863 -913 1163 -650 506 -1793 921 -2875 1043
-163 18 -753 27 -950 13z"
        />
      </g>
    </svg>
  );
};

export default Logo;
