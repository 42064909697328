import * as React from "react";
import { useState } from "react";
import Box from "@mui/material/Box";
import LabelIcon from "@mui/icons-material/Label";
import posts from "../posts";
import HomeIcon from "@mui/icons-material/Home";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import {
  useTranslate,
  DashboardMenuItem,
  MenuItemLink,
  MenuProps,
  useSidebarState,
  usePermissions,
} from "react-admin";

import SubMenu from "./SubMenu";
import Logo from "./Logo";
import Utente from "../utente";
import Website from "../website";
import { StyledMenuItemLink } from "./CustomComponents";
import Task from "../task";

const Menu = ({ dense = false }) => {
  const { permissions } = usePermissions();

  const [open] = useSidebarState();

  const UserMenu = () => {
    return (
      <Box
        sx={{
          width: open ? 350 : 50,
          marginTop: 2,
          marginBottom: 2,
          transition: (theme) =>
            theme.transitions.create("width", {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.leavingScreen,
            }),
        }}
      >
        <Box sx={{ m: 5, p: 2 }}>
          <Logo />
        </Box>
        <StyledMenuItemLink
          to="/"
          primaryText="Dashboard"
          leftIcon={<HomeIcon sx={{ color: "#fff" }} />} // pass the icon here
          exact
        />
        <StyledMenuItemLink
          to="/task"
          state={{ _scrollToTop: true }}
          primaryText="Tasks"
          leftIcon={<Task.icon sx={{ color: "#fff" }} />}
          dense={dense}
        />
        {permissions && (
          <>
            <StyledMenuItemLink
              to="/articolo"
              state={{ _scrollToTop: true }}
              primaryText="Articoli"
              leftIcon={<posts.icon sx={{ color: "#fff" }} />}
              dense={dense}
            />
            <StyledMenuItemLink
              to="/utente"
              state={{ _scrollToTop: true }}
              primaryText="Utenti"
              leftIcon={<Utente.icon sx={{ color: "#fff" }} />}
              dense={dense}
            />
            <StyledMenuItemLink
              to="/website"
              state={{ _scrollToTop: true }}
              primaryText="Siti"
              leftIcon={<Website.icon sx={{ color: "#fff" }} />}
              dense={dense}
            />
          </>
        )}
      </Box>
    );
  };
  return <UserMenu />;
};

export default Menu;
