import {
  ArrayInput,
  AutocompleteArrayInput,
  BooleanInput,
  Create,
  DeleteButton,
  EditButton,
  FilterButton,
  FunctionField,
  ListButton,
  NumberField,
  ReferenceArrayInput,
  required,
  RichTextField,
  SelectArrayInput,
  SelectInput,
  Show,
  ShowButton,
  SimpleFormIterator,
  SimpleShowLayout,
  TopToolbar,
} from "react-admin/dist/cjs";
import { Edit, ReferenceInput, SimpleForm, TextInput } from "react-admin";
import { StyledDatagrid, PaperWithTitle } from "../layout/CustomComponents";
import { Box, Grid, Stack, Typography } from "@mui/material";

export const PostCreate = () => {
  const utenteRendered = (choice) => `${choice.nome} ${choice.cognome}`;

  return (
    <PaperWithTitle title="Crea Articolo">
      <Create redirect="list">
        <SimpleForm>
          <Grid container spacing={2} sx={{ p: 2 }}>
            <Grid item xs={12}>
              <TextInput source="url" validate={[required()]} fullWidth />
            </Grid>
            <Grid item md={12}>
              <Typography variant="body1">
                Priorità e Utente Preferito
              </Typography>
            </Grid>
            <Grid item md={4}>
              <ReferenceInput reference="utente" source="utente_preferito">
                <SelectInput
                  source="utente"
                  label="utente preferito"
                  optionText={utenteRendered}
                  fullWidth
                />
              </ReferenceInput>
            </Grid>
            <Grid item md={8}>
              <Stack spacing={1} direction="row" justifyContent="flex-end">
                <BooleanInput source="priorita" />
              </Stack>
            </Grid>
            <Grid item md={12}>
              <Typography variant="body1">Sito e Categoria</Typography>
            </Grid>
            <Grid item md={4}>
              <ReferenceInput reference="categoria" source="categoria.id">
                <SelectInput
                  source="categoria"
                  label="categoria"
                  optionText="categoria"
                  validate={[required()]}
                  fullWidth
                />
              </ReferenceInput>
            </Grid>
            <Grid item md={8}>
              <ReferenceArrayInput source="websites_ids" reference="website">
                <SelectArrayInput
                  optionText="sito"
                  source="sito"
                  validate={[required()]}
                  fullWidth
                />
              </ReferenceArrayInput>
            </Grid>
            <Grid item md={12}>
              <Typography variant="body1">Informazioni aggiuntive</Typography>
              <TextInput
                source="titolo_futuro"
                label="Titolo"
                fullWidth
                validate={[required()]}
                helperText={false}
              />
              <TextInput
                source="descrizione"
                label="Descrizione"
                multiline
                fullWidth
              />
            </Grid>
          </Grid>
        </SimpleForm>
      </Create>
    </PaperWithTitle>
  );
};
