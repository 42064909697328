import NotificationsIcon from "@mui/icons-material/Notifications";
import {
  Avatar,
  Badge,
  Divider,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Menu,
  MenuItem,
  MenuList,
  Tooltip,
} from "@mui/material";
import { useState } from "react";
import { useGetList } from "react-admin";
import { Link } from "react-router-dom";

export const NotificationShow = {
  setViewed: (value) => {
    localStorage.setItem("notificationLast", isNaN(value) ? 0 : value);
  },
  lastView: () => {
    let notificationLast = localStorage.getItem("notificationLast");
    return isNaN(notificationLast) ? 0 : notificationLast;
  },
  reset: () => {
    localStorage.setItem("notificationLast", 0);
  },
};

export const NotificationButton = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState();

  const { data, total, isLoading } = useGetList("notifiche", {
    pagination: { page: 1, perPage: 5 },
    filter: { last: NotificationShow.lastView() },
  });

  const handleClose = () => setIsOpen(false);

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
    setIsOpen(!isOpen);
    NotificationShow.setViewed(getLastIdFromNotification());
  };

  const getLastIdFromNotification = () => {
    const lastView = NotificationShow.lastView();
    return data
      ? data.length > 0
        ? data.reduce((accumulator, currentValue) => {
            return currentValue.id > lastView ? currentValue.id : lastView;
          })
        : lastView
      : lastView;
  };
  const badgeContentShow = () => {
    const lastView = NotificationShow.lastView();
    return getLastIdFromNotification() - lastView;
  };
  return (
    <>
      <IconButton id="notificationButton" onClick={handleOpen}>
        <Badge
          showZero={false}
          badgeContent={badgeContentShow()}
          color="warning"
        >
          <NotificationsIcon />
        </Badge>
      </IconButton>
      {isOpen && (
        <Menu anchorEl={anchorEl} open={isOpen} onClose={handleClose}>
          {data &&
            data.map((record) => (
              <ListItem>
                <ListItemAvatar>
                  <Tooltip title={record.cognome + " " + record.nome} arrow>
                    <Avatar
                      sx={{
                        width: 24,
                        height: 24,
                        fontSize: "1em",
                        p: 0.5,
                        mr: 1,
                      }}
                    >
                      {record.iniziali}
                    </Avatar>
                  </Tooltip>
                </ListItemAvatar>
                <ListItemText
                  secondaryTypographyProps={{ fontSize: "0.8em" }}
                  primary={record.testo}
                  secondary={record.timestamp}
                />
              </ListItem>
            ))}
          <MenuItem
            component={Link}
            // @ts-ignore
            {...props}
            to="/impostazioni"
            onClick={handleClose}
          >
            Visualizza tutte le notifiche
          </MenuItem>
        </Menu>
      )}
    </>
  );
};
