import {
  Button,
  Confirm,
  DateField,
  EditButton,
  FunctionField,
  Loading,
  Show,
  SimpleForm,
  TextField,
  UrlField,
  usePermissions,
  useRecordContext,
  useRefresh,
  useShowContext,
  useShowController,
} from "react-admin";
import {
  InternalLinkField,
  PaperWithTitleNoPadding,
  StatoField,
} from "../layout/CustomComponents";
import {
  Box,
  Grid,
  Grow,
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useUpdate } from "react-admin";
import { useState } from "react";
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from "@mui/lab";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { get } from "lodash";
import { ModalImage } from "../layout/modalImage/ModalImage";

const CustomUpdatePostsButton = () => {
  const [open, setOpen] = useState(false);
  const [stato, setStato] = useState();
  const refresh = useRefresh();
  const record = useRecordContext();
  const largeScreen = useMediaQuery((theme) => theme.breakpoints.up("md"));

  const [completa, { isLoading }] = useUpdate("task", {
    id: record.id,
    data: { stato: stato },
    previousData: record,
    options: {
      onSuccess: () => {
        refresh();
      },
    },
  });

  const handleClick = (stato) => {
    setStato(stato);
    setOpen(true);
  };
  const handleDialogClose = () => setOpen(false);
  const handleConfirm = () => {
    completa();
    setOpen(false);
  };

  if (isLoading)
    return <Loading loadingSecondary="Chiusura del Task in corso" />;

  return (
    <>
      {record.stato == "assegnato" && (
        <Stack
          spacing={2}
          direction={largeScreen ? "row" : "column"}
          justifyContent="center"
          alignItems="center"
        >
          <Button
            color="white"
            className="completaButton"
            onClick={() => handleClick("chiuso")}
            disabled={isLoading}
            size="medium"
          >
            COMPLETA TASK
          </Button>
          <Button
            color="white"
            className="cancellaButton"
            onClick={() => handleClick("cancellato")}
            disabled={isLoading}
            size="medium"
          >
            CANCELLA TASK
          </Button>
        </Stack>
      )}
      <Confirm
        isOpen={open}
        loading={isLoading}
        title={stato == "chiuso" ? "Completa Task" : "Cancella Task"}
        content={
          stato == "chiuso"
            ? "Sei sicuro di volere chiudere il task assegnato?"
            : "Sei sicuro di volere cancellare il task assegnato?"
        }
        onConfirm={handleConfirm}
        confirm="SI"
        cancel="NO"
        onClose={handleDialogClose}
      />
    </>
  );
};

const TimelineTask = () => {
  const record = useRecordContext();
  return (
    <PaperWithTitleNoPadding title="Timeline">
      <Timeline position="alternate">
        <TimelineItem>
          <TimelineOppositeContent color="text.secondary">
            <DateField source="data_assegnazione" showTime />
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineDot color="secondary" />
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>assegnato</TimelineContent>
        </TimelineItem>
        {record.data_chiusura ? (
          <TimelineItem>
            <TimelineOppositeContent color="text.secondary">
              <DateField source="data_chiusura" showTime />
            </TimelineOppositeContent>
            <TimelineSeparator>
              {record.stato == "chiuso" ? (
                <TimelineDot color="secondary" />
              ) : (
                <TimelineDot color="error" />
              )}
            </TimelineSeparator>
            <TimelineContent>{record.stato}</TimelineContent>
          </TimelineItem>
        ) : (
          <TimelineItem>
            <TimelineOppositeContent color="text.secondary"></TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot color="info" />
            </TimelineSeparator>
            <TimelineContent>In lavorazione</TimelineContent>
          </TimelineItem>
        )}
      </Timeline>
    </PaperWithTitleNoPadding>
  );
};

export const CopyTitoloFuturo = ({ source }) => {
  const record = useRecordContext();
  const value = get(record, source);

  const copyTitleClick = () => {
    navigator.clipboard.writeText(value);
  };

  return (
    <>
      <span>
        <IconButton
          aria-label="Copy Titolo"
          size="small"
          onClick={copyTitleClick}
        >
          <ContentCopyIcon />
        </IconButton>
      </span>
      <Typography variant="body2" component="span">
        {value}
      </Typography>
    </>
  );
};

export const EditArticoloAssociato = ({ source }) => {
  const record = useRecordContext();
  const value = get(record, source);

  return (
    <>
      <EditButton
        label="Modifica Articolo"
        resource="articolo"
        record={{ id: value }}
      />
    </>
  );
};

export const TaskShow = () => {
  const { permissions } = usePermissions();
  return (
    <Show actions={false} emptyWhileLoading>
      <Grid container spacing={0} flexDirection="row-reverse">
        <Grid item lg={4} xs={12}>
          <TimelineTask />
        </Grid>

        <Grid item lg={8} xs={12}>
          <PaperWithTitleNoPadding title="Visualizza Task">
            <Grid container spacing={2} sx={{ p: 4 }}>
              <Grid item md={9} xs={12}>
                <Stack spacing={1}>
                  <Typography variant="body1">URL: </Typography>
                  <UrlField
                    source="articolo_website.articolo.url"
                    target="_blank"
                  />
                </Stack>
              </Grid>
              <Grid item md={3} xs={12}>
                <Stack spacing={1}>
                  <Typography variant="body1">Stato: </Typography>
                  <StatoField source="stato" />
                </Stack>
              </Grid>
              <Grid item md={10} xs={12}>
                <Stack spacing={1}>
                  <Typography variant="body1">Categoria: </Typography>
                  <TextField source="articolo_website.articolo.categoria.categoria" />
                </Stack>
              </Grid>
              <Grid item md={10} xs={12}>
                <Stack spacing={1}>
                  <Typography variant="body1">Priorità: </Typography>
                  <FunctionField
                    label=""
                    render={(record) =>
                      record.articolo_website &&
                      record.articolo_website.articolo.priorita
                        ? "alta"
                        : "normale"
                    }
                  />
                </Stack>
              </Grid>
              <Grid item md={10} xs={12}>
                {permissions && (
                  <Stack spacing={1}>
                    <Typography variant="body1">Assegnato a: </Typography>
                    <FunctionField
                      label="Nome"
                      render={(record) =>
                        `${record.utente.nome} ${record.utente.cognome}`
                      }
                    />
                    <InternalLinkField
                      resource="utente"
                      path="utente.id"
                      name="utente.email"
                    />
                  </Stack>
                )}
              </Grid>
              <Grid item md={12} xs={12}>
                <Stack spacing={1}>
                  <Typography variant="body1">Sito : </Typography>
                  {permissions ? (
                    <InternalLinkField
                      resource="website"
                      path="articolo_website.website.id"
                      name="articolo_website.website.sito"
                    />
                  ) : (
                    <TextField source="articolo_website.website.sito" />
                  )}
                </Stack>
              </Grid>
              <Grid item md={12} xs={12} textAlign="center">
                <CustomUpdatePostsButton />
              </Grid>
            </Grid>
          </PaperWithTitleNoPadding>
          <PaperWithTitleNoPadding title="Dettagli Articolo">
            <Stack spacing={1} sx={{ p: 4 }}>
              <Typography variant="body1">Titolo da inserire: </Typography>
              <Stack spacing={1} direction="row">
                <CopyTitoloFuturo source="articolo_website.articolo.titolo_futuro" />
              </Stack>
              <span></span>
              <Typography variant="body1">Annotazioni: </Typography>
              <TextField
                source="articolo_website.articolo.descrizione"
                label="Descrizione"
                component="p"
                sx={{ whiteSpace: "pre-wrap" }}
              />
              <EditArticoloAssociato source="articolo_website.articolo.id" />
            </Stack>
          </PaperWithTitleNoPadding>
          <PaperWithTitleNoPadding title="Immagini Correlate" collapsed={true}>
            <Stack spacing={1} sx={{ p: 4 }}>
              <ModalImage delete={false} upload={false} />
            </Stack>
          </PaperWithTitleNoPadding>
        </Grid>
      </Grid>
    </Show>
  );
};
