import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import { EditGuesser } from "react-admin";
import { PostEdit } from "./postEdit";
import { PostList } from "./postList";
import { PostCreate } from "./postsCreate";
import { PostShow } from "./postShow";

export default {
  list: PostList,
  edit: PostEdit,
  create: PostCreate,
  show: PostShow,
  icon: ArticleOutlinedIcon,
};
