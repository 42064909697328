import GroupIcon from "@mui/icons-material/Group";
import { UtenteCreate } from "./utenteCreate";
import { UtenteEdit } from "./utenteEdit";
import { UtenteList } from "./utenteList";
import { UtenteShow } from "./utenteShow";

export default {
  create: UtenteCreate,
  list: UtenteList,
  edit: UtenteEdit,
  show: UtenteShow,
  icon: GroupIcon,
};
