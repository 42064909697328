import {
  ArrayInput,
  AutocompleteArrayInput,
  BooleanInput,
  Create,
  DeleteButton,
  EditButton,
  FilterButton,
  FunctionField,
  ListButton,
  NumberField,
  ReferenceArrayInput,
  required,
  SelectArrayInput,
  SelectInput,
  Show,
  ShowButton,
  SimpleFormIterator,
  SimpleShowLayout,
  TopToolbar,
} from "react-admin/dist/cjs";
import { Edit, ReferenceInput, SimpleForm, TextInput } from "react-admin";
import { StyledDatagrid, PaperWithTitle } from "../layout/CustomComponents";
import { Box, Grid, Stack, Typography } from "@mui/material";
import { useLocation } from "react-router-dom";

export const TaskCreate = () => {
  const { state } = useLocation();
  const utenteRendered = (choice) => `${choice.nome} ${choice.cognome}`;
  return (
    <PaperWithTitle title="Assegna Task">
      <Create redirect="list">
        <SimpleForm>
          <Grid container spacing={2} sx={{ p: 2 }}>
            <Grid item xs={12}>
              <Typography variant="body1">Articolo: </Typography>
              <Typography variant="body2">
                {state.record.articolo_url}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body1">Sito: </Typography>
              <Typography variant="body2">{state.record.url}</Typography>
            </Grid>
            <Grid item xs={6}>
              <ReferenceInput reference="utente" source="utente.id">
                <SelectInput
                  source="utente"
                  label="utente"
                  optionText={utenteRendered}
                  validate={[required()]}
                  fullWidth
                />
              </ReferenceInput>
            </Grid>
          </Grid>
        </SimpleForm>
      </Create>
    </PaperWithTitle>
  );
};
