import {
  CreateButton,
  FilterButton,
  FunctionField,
  SearchInput,
  SelectInput,
  TopToolbar,
  useRecordContext,
} from "react-admin/dist/cjs";
import { ReferenceInput } from "react-admin";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import {
  ArrayField,
  ChipField,
  DateField,
  List,
  SingleFieldList,
  TextField,
  UrlField,
} from "react-admin";
import { StyledDatagrid, PaperWithTitle } from "../layout/CustomComponents";
import { Tooltip } from "@mui/material";

const postFilters = [
  <SearchInput source="q" alwaysOn />,
  <ReferenceInput source="sito" label="Website" reference="website">
    <SelectInput optionText="sito" />
  </ReferenceInput>,
  <ReferenceInput source="categoria" label="Categoria" reference="categoria">
    <SelectInput optionText="categoria" />
  </ReferenceInput>,
  <SelectInput
    source="stato"
    label="Stato"
    choices={[
      { id: "chiuso", name: "Chiuso" },
      { id: "assegnato", name: "Assegnato" },
      { id: "cancellato", name: "Cancellato" },
      { id: "disponibile", name: "Disponibile" },
    ]}
  />,
];

const PostListActions = () => (
  <TopToolbar>
    <CreateButton size="medium" label="Aggiungi Articolo" />
    <FilterButton className="customButton" label="Aggiungi Filtro" />
  </TopToolbar>
);

const FormattedWebsite = ({ source }) => {
  const record = useRecordContext();
  return (
    <ChipField
      color={
        record && record["task_status"] == "assegnato"
          ? "info"
          : record["task_status"] == "chiuso"
          ? "success"
          : record["task_status"] == "cancellato"
          ? "error"
          : "default"
      }
      source={source}
    />
  );
};

export const PostList = () => (
  <PaperWithTitle title="Lista Articoli">
    <List
      filters={postFilters}
      actions={<PostListActions />}
      sort={{ field: "id", order: "DESC" }}
    >
      <StyledDatagrid
        size="medium"
        bulkActionButtons={false}
        rowClick="show"
        optimized
      >
        <FunctionField
          label=""
          render={(record) =>
            record.priorita ? (
              <Tooltip title="Priorità Alta">
                <PriorityHighIcon color="error" />
              </Tooltip>
            ) : (
              ""
            )
          }
        />
        <FunctionField
          label=""
          render={(record) =>
            record.utente_preferito > 0 ? (
              <Tooltip title={record.utente_preferito_nome}>
                <AssignmentIndIcon color="secondary" />
              </Tooltip>
            ) : (
              ""
            )
          }
        />
        <UrlField source="url" target={"_blank"} />
        <ArrayField source="website" label="Website assegnato" sortable={false}>
          <SingleFieldList linkType={false}>
            <FormattedWebsite source="sito" />
          </SingleFieldList>
        </ArrayField>
        <TextField source="categoria.categoria" label="Categoria" />
        <DateField source="data_inserimento" />
      </StyledDatagrid>
    </List>
  </PaperWithTitle>
);
