import { Fade, Modal, Stack, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  useCreate,
  useDelete,
  useRecordContext,
  useRefresh,
} from "react-admin";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import { apiUrl } from "../../dataProvider";

export const DeleteImageButton = (props) => {
  const [deleteOne, { isLoading, isSuccess }] = useDelete();
  const [done, setDone] = useState(false);
  const refresh = useRefresh();

  const handleDelete = (image) => {
    deleteOne("attachment", { id: image.id, previousData: image }).then(() =>
      refresh()
    );
  };

  if (isLoading) {
    return <p>Deleting..</p>;
  }

  if (isSuccess && !done) {
    refresh();
    setDone(true);
  }

  return (
    <RemoveCircleIcon
      onClick={() => handleDelete(props.image)}
      sx={{ color: "red", position: "absolute" }}
    />
  );
};

export const ModalImage = (props) => {
  const record = useRecordContext();
  const [data, setData] = useState({
    id_articolo: record.id,
    subject: "",
    payload: "",
  });
  const refresh = useRefresh();
  const dataFetchedRef = useRef(false);
  const needRefresh = useRef(false);
  const [open, setOpen] = useState(false);
  const [image, setImage] = useState("false");

  const [create, { isLoading, isSuccess }] = useCreate();

  if (isSuccess && needRefresh.current) {
    refresh();
    needRefresh.current = false;
  }

  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    needRefresh.current = true;
    if (data.subject.length > 0)
      create("attachment", {
        data: data,
      });
  });

  if (isLoading) {
    return <p>Uploading..</p>;
  }

  const handleChange = (e) => {
    let files = e.target.files;
    let file = files[0];
    // Make new FileReader
    let reader = new FileReader();
    // Convert the file to base64 text
    reader.readAsDataURL(file);
    reader.onload = () => {
      // Make a fileInfo Object
      file = {
        id_articolo: record.id,
        subject: file.name,
        payload: reader.result,
      };
      setData(file);
      dataFetchedRef.current = false;
    }; // reader.onload
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleImage = (value) => {
    setImage(value);
    setOpen(true);
  };

  return (
    <div>
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="normal"
        sx={{ m: 2 }}
        spacing={2}
      >
        {record.attachments &&
          record.attachments.map((tile) => (
            <div class="modal-image">
              {props.delete ? <DeleteImageButton image={tile} /> : <div />}
              <img
                src={`${apiUrl}/${tile.url}`}
                alt={tile.subject}
                onClick={() => handleImage(`${apiUrl}/${tile.url}`)}
                style={{ maxHeight: "20em" }}
              />
            </div>
          ))}
      </Stack>

      {props.upload ? (
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          sx={{ m: 2 }}
        >
          <input
            accept="image/*"
            id="contained-button-file"
            multiple
            type="file"
            style={{ display: "none" }}
            onChange={handleChange}
          />

          <label htmlFor="contained-button-file">
            <Button variant="contained" color="primary" component="span">
              Carica immagine
            </Button>
          </label>
        </Stack>
      ) : (
        <div />
      )}

      <Modal
        open={open}
        onClose={handleClose}
        closeAfterTransition
        sx={{
          alignItems: "center",
          justifyContent: "center",
          display: "flex",
        }}
      >
        <img
          src={image}
          alt="asd"
          sx={{ maxHeight: "90%", maxWidth: "90%", outline: "none" }}
        />
      </Modal>
    </div>
  );
};
