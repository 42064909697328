import { usePermissions } from "react-admin";
import { DashboardAdmin } from "./dashboardAdmin";
import { DashboardUtente } from "./dashboardUtente";

export const DashboardRoute = () => {
  const { permissions } = usePermissions();
  if (permissions) {
    return <DashboardAdmin />;
  } else {
    return <DashboardUtente />;
  }
};
