import {
  Avatar,
  Card,
  Grid,
  Grow,
  Paper,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import {
  Button,
  Datagrid,
  DateField,
  Error,
  FunctionField,
  ListContextProvider,
  Loading,
  Resource,
  SimpleList,
  Toolbar,
  useGetList,
  useGetOne,
  usePermissions,
} from "react-admin";
import {
  CustomTextField,
  PaperWithTitle,
  StatoField,
  StyledDatagrid,
} from "../layout/CustomComponents";
import CardWithIcon from "./cardWithIcon";
import TaskOutlinedIcon from "@mui/icons-material/TaskOutlined";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import CardWithChart from "./cardWithChart";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";

const CutomTaskShow = () => {
  const sort = { field: "id", order: "DESC" };
  const { data, total, isLoading } = useGetList("task", {
    pagination: { page: 1, perPage: 10 },
    sort,
    filter: { stato: "assegnato" },
  });

  return (
    <PaperWithTitle title="Ultimi 10 Task Assegnati">
      <StyledDatagrid
        data={data}
        total={total}
        isLoading={isLoading}
        sort={sort}
        bulkActionButtons={false}
        rowClick={(id, resource, record) => {
          return `/task/${id}/show`;
        }}
      >
        <FunctionField
          label=""
          render={(record) =>
            record.articolo_website.articolo.priorita ? (
              <Tooltip title="Priorità Alta">
                <PriorityHighIcon color="error" />
              </Tooltip>
            ) : (
              ""
            )
          }
        />
        <CustomTextField source="articolo_website.website.sito" label="Sito" />
        <CustomTextField
          source="articolo_website.articolo.url"
          label="Articolo"
        />
        <StatoField source="stato" textAlign="center" />
      </StyledDatagrid>
    </PaperWithTitle>
  );
};

export const DashboardUtente = () => {
  const { data, isLoading, error } = useGetOne("stats", {
    id: "utente",
  });

  if (isLoading) return <Loading />;
  if (error) return <Error />;

  return (
    <>
      <Grid container>
        <Grid item xl={3} md={6} xs={12}>
          <CardWithIcon
            icon={<TaskOutlinedIcon fontSize="large" />}
            title="Task"
            subtitle="Completati"
            value={data.percentuale_task + "%"}
            to="/task"
            note={data.totale_task_aperti + " Task ancora da completare"}
          ></CardWithIcon>
        </Grid>
        <Grid item xl={3} md={6} xs={12}>
          <CardWithIcon
            icon={<DescriptionOutlinedIcon fontSize="large" />}
            title="Articoli"
            subtitle="Questo mese"
            value={data.task_mensili.totale}
            to="/task"
            note={
              (data.task_mensili.mensile_before > data.task_mensili.totale
                ? "-"
                : "") +
              data.task_mensili.mensile_before_perc +
              "% Task rispetto al mese precedente"
            }
          />
        </Grid>
        <Grid item xl={3} md={6} xs={12}>
          <CardWithChart
            icon={<DescriptionOutlinedIcon fontSize="large" />}
            title="Articoli"
            subtitle="Totali"
            value={data.totale_task}
            data={data.totale_task_per_mese}
            to="/task"
          />
        </Grid>
        <Grid item xl={3} md={6} xs={12}>
          <CardWithIcon
            icon={<DescriptionOutlinedIcon fontSize="large" />}
            title="Backlog"
            subtitle="Articoli da Recuperare"
            value={data.totale_backlog}
            to="/task"
            note={
              data.totale_backlog > 0 &&
              data.backlog
                .map((backlog) => {
                  return backlog.backlog + " per " + backlog.website;
                })
                .join(", ")
            }
          />
        </Grid>
      </Grid>
      <CutomTaskShow />
    </>
  );
};
