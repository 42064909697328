import { Grid } from "@mui/material";
import { Stack } from "@mui/system";
import { itIT, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/it";
import { PaperWithTitleNoPadding } from "../layout/CustomComponents";
import { CustomDownloadReportButton } from "./ReportDownload";
import { CustomFerieShow } from "./GestioneFerie";
import { NotificheShow } from "./NotificheShow";
import { usePermissions } from "react-admin";

export const ImpostazioniShowForAdmin = () => {
  return (
    <ImpostazioniShowDefault
      showFerie={false}
      showReport={false}
      showNotifiche={true}
      isAdmin={true}
    />
  );
};

export const ImpostazioniShowForUser = () => {
  return (
    <ImpostazioniShowDefault
      showFerie={true}
      showReport={true}
      showNotifiche={true}
      isAdmin={false}
    />
  );
};

export const ImpostazioniShow = () => {
  const { permissions } = usePermissions();
  if (permissions) {
    return <ImpostazioniShowForAdmin />;
  } else {
    return <ImpostazioniShowForUser />;
  }
};

export const ImpostazioniShowDefault = ({
  userId,
  collapsed,
  showFerie = true,
  showReport = true,
  showNotifiche = true,
  isAdmin = true,
}) => {
  return (
    <>
      <LocalizationProvider
        dateAdapter={AdapterDayjs}
        adapterLocale="it"
        localeText={
          itIT.components.MuiLocalizationProvider.defaultProps.localeText
        }
      >
        <Grid container>
          {showFerie && (
            <Grid item xs={12} lg={8}>
              <PaperWithTitleNoPadding
                title="Gestione Giorni OFF"
                collapsed={collapsed}
              >
                <CustomFerieShow userId={userId} />
              </PaperWithTitleNoPadding>
            </Grid>
          )}
          {showReport && (
            <Grid item xs={12} lg={4}>
              <PaperWithTitleNoPadding title="Report" collapsed={collapsed}>
                <Stack direction="row" spacing={2}>
                  <CustomDownloadReportButton userId={userId} />
                </Stack>
              </PaperWithTitleNoPadding>
            </Grid>
          )}
          {showNotifiche && (
            <Grid item xs={12} lg={6}>
              <PaperWithTitleNoPadding
                title="Ultime Notifiche"
                collapsed={collapsed}
              >
                <Stack direction="row" spacing={2}>
                  <NotificheShow isAdmin={isAdmin} />
                </Stack>
              </PaperWithTitleNoPadding>
            </Grid>
          )}
        </Grid>
      </LocalizationProvider>
    </>
  );
};
