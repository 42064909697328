import {
  Create,
  SimpleForm,
  BooleanInput,
  TextInput,
  required,
  NumberInput,
} from "react-admin";

import { PaperWithTitle } from "../layout/CustomComponents";

export const WebsiteCreate = () => (
  <PaperWithTitle title="Crea nuovo Sito Gestito">
    <Create redirect="list">
      <SimpleForm>
        <BooleanInput source="abilitato" />
        <BooleanInput source="weekend" label="Programmazione nel weekend" />
        <TextInput source="sito" validate={[required()]} fullWidth />
        <TextInput source="url" validate={[required()]} fullWidth />
        <NumberInput source="numero_articoli" validate={[required()]} />
      </SimpleForm>
    </Create>
  </PaperWithTitle>
);
