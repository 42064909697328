import { defaultTheme } from "react-admin";

export const themeOptions = {
  ...defaultTheme,
  palette: {
    type: "light",
    primary: {
      main: "#8cb369",
    },
    secondary: {
      main: "#5b8e7d",
    },
    error: {
      main: "#BC4B51",
    },
    info: {
      main: "#F4E285",
    },
    warning: {
      main: "#f4a259",
    },
    white: {
      main: "#ffffff",
    },
  },
  typography: {
    fontFamily: "Montserrat",
    fontSize: 18,
    h1: {
      fontWeight: 600,
      color: "#757575",
    },
    h2: {
      fontWeight: 800,
      fontSize: "20px",
      color: "#757575",
    },
    h3: {
      fontWeight: 700,
      fontSize: "16px",
      color: "#757575",
    },
    button: {
      fontSize: "1.1rem",
      fontWeight: 600,
    },
    body1: {
      fontSize: "1rem",
      fontWeight: 300,
    },
    body2: {
      fontSize: "1.1rem",
    },
  },
  shape: {
    borderRadius: 0,
  },
  overrides: {
    ...defaultTheme.components,
    MuiLink: {
      styleOverrides: {
        root: {
          color: "#5b8e7d",
        },
      },
    },
    RaLayout: {
      styleOverrides: {
        content: {
          backgroundColor: "#5b8e7d",
        },
      },
    },
    RaSidebar: {
      styleOverrides: {
        content: {
          backgroundColor: "#5b8e7d",
        },
      },
    },
  },
  props: {
    MuiAppBar: {
      color: "inherit",
    },
  },
};
