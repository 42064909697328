import * as React from "react";
import { FC, createElement } from "react";
import { Card, Box, Typography, Divider } from "@mui/material";
import { Link, To } from "react-router-dom";
import { Stack } from "@mui/system";

const CardWithIcon = (props) => {
  const { icon, title, subtitle, to, value, note } = props;

  return (
    // @ts-ignore
    <Card
      sx={{
        minHeight: 150,
        display: "flex",
        flexDirection: "column",
        flex: "1",
        "& a": {
          textDecoration: "none",
          color: "inherit",
        },
        borderRadius: "10px",
        m: 2,
      }}
      elevation={4}
    >
      <Link to={to}>
        <Box
          sx={{
            overflow: "inherit",
            padding: "1em",
            display: "flex",
            justifyContent: "space-between",

            "& .icon": {
              color: "primary.dark",
            },
          }}
        >
          <Box textAlign="left">
            <Stack direction="row">
              <Typography variant="h2" component="h2">
                {title}
              </Typography>
              <Typography variant="h2" sx={{ fontWeight: "400" }}>
                &nbsp;{"| "}
                {subtitle}
              </Typography>
            </Stack>
            <Stack sx={{ p: 2 }} spacing={1}>
              <Typography variant="h2" fontSize="3em">
                {value || "0"}
              </Typography>
              <Typography variant="body1">{note || <br />}</Typography>
            </Stack>
          </Box>
          <Box
            className="icon"
            sx={{
              backgroundColor: "rgba(140, 179, 105, 0.1)",
              color: "primary.dark",
              p: "0.7em",
              maxHeight: "4em",
              maxWidth: "4em",
              borderRadius: "10px",
            }}
          >
            <div>{icon}</div>
          </Box>
        </Box>
      </Link>
    </Card>
  );
};

export default CardWithIcon;
