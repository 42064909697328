import {
  BooleanInput,
  ReferenceArrayInput,
  required,
  SelectArrayInput,
  SelectInput,
} from "react-admin/dist/cjs";
import { Edit, ReferenceInput, SimpleForm, TextInput } from "react-admin";
import { useRecordContext } from "react-admin";

import { PaperWithTitle } from "../layout/CustomComponents";
import { Grid, Stack, Typography } from "@mui/material";

const PostTitle = () => {
  const record = useRecordContext();
  return <span>Articolo {record ? `"${record.url}"` : ""}</span>;
};

export const PostEdit = () => {
  const utenteRendered = (choice) => `${choice.nome} ${choice.cognome}`;

  return (
    <PaperWithTitle title="Modifica Articolo">
      <Edit title={<PostTitle />} redirect="show">
        <SimpleForm>
          <Grid container spacing={2} sx={{ p: 2 }}>
            <Grid item xs={12}>
              <TextInput source="url" validate={[required()]} fullWidth />
            </Grid>
            <Grid item md={12}>
              <Typography variant="body1">
                Priorità e Utente Preferito
              </Typography>
            </Grid>
            <Grid item md={4}>
              <ReferenceInput reference="utente" source="utente_preferito">
                <SelectInput
                  source="utente"
                  label="utente preferito"
                  optionText={utenteRendered}
                  fullWidth
                />
              </ReferenceInput>
            </Grid>
            <Grid item md={8}>
              <Stack spacing={1} direction="row" justifyContent="flex-end">
                <BooleanInput source="priorita" />
              </Stack>
            </Grid>
            <Grid item xs={6}>
              <ReferenceArrayInput source="websites_ids" reference="website">
                <SelectArrayInput
                  optionText="sito"
                  source="sito"
                  validate={[required()]}
                  fullWidth
                />
              </ReferenceArrayInput>
            </Grid>
            <Grid item xs={6}>
              <ReferenceInput reference="categoria" source="categoria.id">
                <SelectInput
                  source="categoria"
                  label="categoria"
                  optionText="categoria"
                  validate={[required()]}
                  fullWidth
                />
              </ReferenceInput>
            </Grid>
            <Grid item md={12}>
              <Typography variant="body1">Informazioni aggiuntive</Typography>
              <TextInput
                source="titolo_futuro"
                label="Titolo"
                fullWidth
                helperText={false}
              />
              <TextInput
                source="descrizione"
                label="Descrizione"
                multiline
                fullWidth
              />
            </Grid>
          </Grid>
        </SimpleForm>
      </Edit>
    </PaperWithTitle>
  );
};
