import LanguageIcon from "@mui/icons-material/Language";
import { WebsiteList } from "./websiteList";
import { ShowGuesser } from "react-admin";
import { WebsiteShow } from "./websiteShow";
import { WebsiteCreate } from "./websiteCreate";
import { WebsiteEdit } from "./websiteEdit";

export default {
  list: WebsiteList,
  edit: WebsiteEdit,
  create: WebsiteCreate,
  show: WebsiteShow,
  icon: LanguageIcon,
};
