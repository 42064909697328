import { Button, Grid, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import {
  PickersDay,
  pickersDayClasses,
  StaticDatePicker,
} from "@mui/x-date-pickers";

import dayjs from "dayjs";
import "dayjs/locale/it";
import { useState } from "react";
import {
  Confirm,
  DateField,
  Loading,
  useCreate,
  useDelete,
  useGetList,
  useNotify,
  usePermissions,
  useRecordContext,
  useRefresh,
} from "react-admin";
import { StyledDatagrid } from "../layout/CustomComponents";
import AddCircleIcon from "@mui/icons-material/AddCircle";

const CustomAddButton = ({ giornooff, userId, permissions }) => {
  const [open, setOpen] = useState(false);
  const refresh = useRefresh();
  const notify = useNotify();

  const [create, { isLoading, isSuccess }] = useCreate();

  const handleClick = () => {
    setOpen(true);
  };
  const handleDialogClose = () => setOpen(false);
  const handleConfirm = (giornoConfirm) => {
    console.log("confirm", giornoConfirm);
    create(permissions ? `utente/${userId}/ferie` : "ferie", {
      data: { data_inizio: giornoConfirm, data_fine: giornoConfirm },
    });
    setOpen(false);
  };

  if (isSuccess) {
    refresh();
    notify("Giorno off aggiunto");
  }

  if (isLoading)
    return <Loading loadingSecondary="Creazione giorno off in corso" />;

  return (
    <>
      <Button
        color="primary"
        onClick={() => handleClick()}
        disabled={isLoading}
      >
        <AddCircleIcon />
      </Button>
      <Confirm
        isOpen={open}
        loading={isLoading}
        title="Aggiungi Giorno off"
        content={
          "Sei sicuro di volere aggiungere " + giornooff + " come giorno off ?"
        }
        onConfirm={() => handleConfirm(giornooff)}
        confirm="SI"
        cancel="NO"
        onClose={handleDialogClose}
      />
    </>
  );
};

const CustomDeleteButton = ({ userId, permissions }) => {
  const [open, setOpen] = useState(false);
  const refresh = useRefresh();
  const notify = useNotify();
  const record = useRecordContext();

  const [completa, { isLoading, isSuccess }] = useDelete(
    permissions ? `utente/${userId}/ferie` : "ferie",
    {
      id: record.id,
      previousData: record,
    }
  );
  if (isSuccess) {
    refresh();
    notify("Giorno off cancellato");
  }

  const handleClick = () => {
    setOpen(true);
  };
  const handleDialogClose = () => setOpen(false);
  const handleConfirm = () => {
    completa();
    setOpen(false);
  };

  if (isLoading)
    return <Loading loadingSecondary="Cancellazione giorno off in corso" />;

  return (
    <>
      <Button
        color="error"
        onClick={() => handleClick("chiuso")}
        disabled={isLoading}
      >
        Elimina
      </Button>
      <Confirm
        isOpen={open}
        loading={isLoading}
        title="Cancella giorno off"
        content="Sei sicuro di volere chiudere il giorno off ?"
        onConfirm={handleConfirm}
        confirm="SI"
        cancel="NO"
        onClose={handleDialogClose}
      />
    </>
  );
};

export const CustomFerieShow = ({ userId }) => {
  const [value, setValue] = useState(dayjs(new Date()));
  const sort = { field: "id", order: "DESC" };
  const { permissions } = usePermissions();

  const { data, total, isLoading } = useGetList(
    permissions
      ? `utente/${userId}/ferie/${value.year()}/${value.month() + 1}`
      : `ferie/${value.year()}/${value.month() + 1}`,
    {
      pagination: { page: 1, perPage: 50 },
      sort,
    }
  );

  const holiday = {
    backgroundColor: "red",
    color: "white !important",
  };
  const ferie = {
    backgroundColor: "orange",
    color: "white !important",
  };

  function isHoliday(dateCheck) {
    return dateCheck.day() === 0 || dateCheck.day() === 6;
  }

  function isFerie(dateCheck) {
    if (data) {
      for (var i = 0; i < data.length; i++) {
        if (dateCheck.isSame(data[i].data, "date")) {
          return true;
        }
      }
    }
    return false;
  }

  const renderWeekPickerDay = (date, selectedDates, pickersDayProps) => {
    let matchedStyles = {};
    if (data) {
      // check ferie
      /*matchedStyles = data.reduce((a, v) => {
          return date.isSame(v.data, "date") ? ferie : a;
        }, {});
        */
      matchedStyles = isFerie(date) ? ferie : {};
    }
    // check sabato e domenica
    matchedStyles = isHoliday(date) ? holiday : matchedStyles;

    return (
      <PickersDay
        {...pickersDayProps}
        sx={{
          ...matchedStyles,
          [`&&.${pickersDayClasses.selected}`]: {
            backgroundColor: "green",
            color: "white !important",
          },
          [`&&.${pickersDayClasses.today}`]: {
            borderColor: "purple",
            borderWidth: "3",
          },
        }}
      />
    );
  };

  return (
    <Grid container sx={{ p: 2 }}>
      <Grid item xl={6} xs={12}>
        <Stack spacing={2} direction="row">
          <Stack>
            <Typography variant="body1">Giorno OFF</Typography>
            <Typography variant="h4">
              {value.format("DD/MM/YYYY")}
              {isHoliday(value) || isFerie(value) ? (
                <></>
              ) : (
                <CustomAddButton
                  giornooff={value.format("DD/MM/YYYY")}
                  userId={userId}
                  permissions={permissions}
                />
              )}
            </Typography>
            <StaticDatePicker
              displayStaticWrapperAs="desktop"
              value={value}
              onMonthChange={(newValue) => {
                setValue(newValue);
              }}
              onChange={(newValue) => {
                setValue(newValue);
              }}
              showToolbar={false}
              renderDay={renderWeekPickerDay}
              disablePast={true}
              maxDate={dayjs(new Date()).add(1, 'month').endOf("month")}
              minDate={dayjs("2023-01-01")}
            />
          </Stack>
          <Stack spacing={1}>
            <Typography sx={holiday}>&nbsp;Giorni Festivi&nbsp;</Typography>
            <Typography sx={ferie}>&nbsp;Giorni OFF&nbsp;</Typography>
            <Typography
              sx={{ backgroundColor: "green", color: "white !important" }}
            >
              &nbsp;Giorno Selezionato&nbsp;
            </Typography>
          </Stack>
        </Stack>
      </Grid>
      <Grid item xl={6} xs={12}>
        <StyledDatagrid
          data={data}
          total={total}
          isLoading={isLoading}
          sort={false}
          bulkActionButtons={false}
        >
          <DateField source="data" label="Giorni OFF" textAlign="center" />
          <CustomDeleteButton userId={userId} permissions={permissions} />
        </StyledDatagrid>
      </Grid>
    </Grid>
  );
};
