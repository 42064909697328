import { Tooltip, useMediaQuery, useTheme } from "@mui/material";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";

import { useState } from "react";
import {
  Button,
  Confirm,
  DateField,
  ExportButton,
  FunctionField,
  List,
  Loading,
  SelectInput,
  TextField,
  useCreate,
  useNotify,
  usePermissions,
  useRedirect,
} from "react-admin";
import { FilterButton, ReferenceInput, TopToolbar } from "react-admin/dist/cjs";

import {
  PaperWithTitle,
  StatoField,
  StyledDatagrid,
} from "../layout/CustomComponents";

const taskFilters = [
  <ReferenceInput source="utente" label="Utente" reference="utente">
    <SelectInput optionText="email" />
  </ReferenceInput>,
  <ReferenceInput source="website" label="Sito" reference="website">
    <SelectInput optionText="sito" />
  </ReferenceInput>,
  <SelectInput
    source="stato"
    label="Stato"
    choices={[
      { id: "chiuso", name: "Chiuso" },
      { id: "assegnato", name: "Assegnato" },
      { id: "cancellato", name: "Cancellato" },
    ]}
  />,
];

const taskUserFilters = [
  <SelectInput
    source="stato"
    label="Stato"
    choices={[
      { id: "chiuso", name: "Chiuso" },
      { id: "assegnato", name: "Assegnato" },
      { id: "cancellato", name: "Cancellato" },
    ]}
  />,
];

const CustomTaskPostsButton = () => {
  const [open, setOpen] = useState(false);
  const redirect = useRedirect();
  const notify = useNotify();

  const [completa, { isLoading }] = useCreate(
    "task",
    { data: { isApproved: true } },
    {
      onSuccess: (data) => {
        redirect("show", "task", data.id);
        notify("Task creato");
      },
      onError: (error) => {
        notify(`Creazione task in errore: ${error.message}`, {
          type: "warning",
        });
      },
    }
  );

  const handleClick = () => {
    setOpen(true);
  };
  const handleDialogClose = () => setOpen(false);
  const handleConfirm = () => {
    completa();
    setOpen(false);
  };

  if (isLoading) return <Loading loadingSecondary="Crazione Task in corso" />;

  return (
    <>
      <Button
        onClick={() => handleClick()}
        disabled={isLoading}
        size="medium"
        label="Crea nuovo Task"
      />

      <Confirm
        isOpen={open}
        loading={isLoading}
        title="Nuovo Task"
        content="Vuoi assegnarti un nuovo Task da completare?"
        onConfirm={handleConfirm}
        confirm="SI"
        cancel="NO"
        onClose={handleDialogClose}
      />
    </>
  );
};

const TaskListActions = () => {
  const { permissions } = usePermissions();
  return (
    <TopToolbar>
      {!permissions && <CustomTaskPostsButton />}
      <ExportButton size="medium" />
      <FilterButton size="medium" className="customButton" />
    </TopToolbar>
  );
};

const CustomEmpty = () => <div>Nessun Task Assegnato</div>;

export const TaskList = () => {
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const { permissions } = usePermissions();
  return (
    <>
      <PaperWithTitle title="Lista Task">
        <List
          filters={permissions ? taskFilters : taskUserFilters}
          actions={<TaskListActions />}
          empty={<CustomEmpty />}
          sort={{ field: "id", order: "DESC" }}
          emptyWhileLoading
        >
          {isSmall ? (
            <StyledDatagrid
              size="small"
              bulkActionButtons={false}
              rowClick="show"
              optimized
            >
              <FunctionField
                label=""
                render={(record) =>
                  record.articolo_website.articolo.priorita ? (
                    <Tooltip title="Priorità Alta">
                      <PriorityHighIcon color="error" />
                    </Tooltip>
                  ) : (
                    ""
                  )
                }
              />
              <TextField
                source="articolo_website.articolo.url"
                label="Articolo"
              />
              {permissions && (
                <FunctionField
                  label="Assegnato"
                  render={(record) =>
                    `${record.utente.nome} ${record.utente.cognome}`
                  }
                />
              )}
              <StatoField source="stato" />
            </StyledDatagrid>
          ) : (
            <StyledDatagrid
              size="medium"
              bulkActionButtons={false}
              rowClick="show"
              optimized
            >
              <FunctionField
                label=""
                render={(record) =>
                  record.articolo_website.articolo.priorita ? (
                    <Tooltip title="Priorità Alta">
                      <PriorityHighIcon color="error" />
                    </Tooltip>
                  ) : (
                    ""
                  )
                }
              />

              <TextField
                source="articolo_website.articolo.url"
                label="Articolo"
              />
              {permissions && (
                <FunctionField
                  label="Assegnato"
                  render={(record) =>
                    `${record.utente.nome} ${record.utente.cognome}`
                  }
                />
              )}
              <StatoField source="stato" />
              <DateField source="data_assegnazione" showTime />
              <DateField source="data_chiusura" showTime />
            </StyledDatagrid>
          )}
        </List>
      </PaperWithTitle>
    </>
  );
};
