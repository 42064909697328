import {
  List,
  TextField,
  UrlField,
  CreateButton,
  TopToolbar,
  FunctionField,
  NumberField,
  WrapperField,
} from "react-admin";
import WarningIcon from "@mui/icons-material/Warning";

import {
  EnabledIconField,
  PaperWithTitle,
  StyledDatagrid,
} from "../layout/CustomComponents";

const WebsiteListActions = () => (
  <TopToolbar>
    <CreateButton size="medium" label="Aggiungi Sito" />
  </TopToolbar>
);

export const WebsiteList = () => (
  <PaperWithTitle title="Lista Siti Gestiti">
    <List actions={<WebsiteListActions />} filters={false}>
      <StyledDatagrid rowClick="show" size="medium" bulkActionButtons={false}>
        <EnabledIconField source="abilitato" textAlign="center" />
        <TextField source="sito" />
        <UrlField source="url" />
        <NumberField
          source="numero_articoli"
          label="Totale Articoli Desiderati"
          textAlign="center"
        />

        <WrapperField label="Totale Articoli Assegnati">
          <FunctionField
            source="Articoli Programmati"
            textAlign="center"
            sx={{ fontSize: "20px", maxWidth: "100px" }}
            render={(record) => {
              if (record.numero_articoli_programmati < record.numero_articoli) {
                return (
                  <WarningIcon
                    sx={{
                      color: "#f4a259",
                    }}
                  />
                );
              }
            }}
          />{" "}
          <NumberField
            source="numero_articoli_programmati"
            textAlign="center"
            defaultValue={0}
          />
        </WrapperField>
      </StyledDatagrid>
    </List>
  </PaperWithTitle>
);
