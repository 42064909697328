import {
  List,
  TextField,
  FilterButton,
  CreateButton,
  TopToolbar,
  ReferenceInput,
  EmailField,
  SelectInput,
  NumberField,
  ChipField,
  ArrayField,
  SingleFieldList,
} from "react-admin";

import {
  EnabledIconField,
  PaperWithTitle,
  StyledDatagrid,
} from "../layout/CustomComponents";

const UtenteListActions = () => (
  <TopToolbar>
    <CreateButton size="medium" label="Aggiungi Utente" />
    <FilterButton className="customButton" label="Aggiungi Filtro" />
  </TopToolbar>
);

const UtenteFilter = [
  <ReferenceInput source="website" label="Website" reference="website">
    <SelectInput optionText="sito" />
  </ReferenceInput>,
];

export const UtenteList = () => (
  <PaperWithTitle title="Lista Utenti">
    <List actions={<UtenteListActions />} filters={UtenteFilter}>
      <StyledDatagrid
        rowClick="show"
        // expand={<InvoiceShow />}
        size="medium"
        bulkActionButtons={false}
      >
        <EnabledIconField
          label="Abilitato"
          textAlign="center"
          source="abilitato"
        />
        <TextField source="nome" />
        <TextField source="cognome" />
        <EmailField source="email" textAlign="center" />
        <ArrayField
          source="categorie_assegnate"
          label="Categorie Assegnate"
          sortable={false}
        >
          <SingleFieldList linkType={false}>
            <ChipField source="categoria"></ChipField>
          </SingleFieldList>
        </ArrayField>
        <ArrayField
          source="articoli_aggiuntivi"
          label="Articoli Aggiuntivi"
          sortable={false}
        >
          <SingleFieldList linkType={false}>
            <ChipField source="categoria"></ChipField>
          </SingleFieldList>
        </ArrayField>
        <NumberField source="totale_articoli" textAlign="center" />
      </StyledDatagrid>
    </List>
  </PaperWithTitle>
);
