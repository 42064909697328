import { Button, Grid, Typography } from "@mui/material";
import {
  ArrayInput,
  BooleanInput,
  Edit,
  NumberInput,
  PasswordInput,
  ReferenceArrayInput,
  ReferenceInput,
  SelectArrayInput,
  SelectInput,
  SimpleForm,
  SimpleFormIterator,
  TextInput,
} from "react-admin";
import { PaperWithTitle } from "../layout/CustomComponents";

export const UtenteEdit = () => (
  <PaperWithTitle title="Modifica Utente">
    <Edit redirect="show">
      <SimpleForm>
        <Grid container spacing={1}>
          <Grid item md={2}>
            <BooleanInput source="abilitato" />
          </Grid>
          <Grid item md={6}>
            <TextInput source="email" fullWidth />
          </Grid>
          <Grid item md={4}>
            <PasswordInput source="password" fullWidth />
          </Grid>
          <Grid item md={6}>
            <TextInput source="nome" fullWidth />
          </Grid>
          <Grid item md={6}>
            <TextInput source="cognome" fullWidth />
          </Grid>
          <Grid item md={6}>
            <ReferenceArrayInput
              source="categorie_assegnate_ids"
              reference="categoria"
            >
              <SelectArrayInput
                optionText="categoria"
                source="categoria"
                fullWidth
              />
            </ReferenceArrayInput>
          </Grid>
        </Grid>
        <Typography variant="body1">Assegna Website</Typography>
        <ArrayInput source="ruoli" label={false}>
          <SimpleFormIterator
            inline
            addButton={<Button>Aggiungi</Button>}
            removeButton={<Button sx={{ color: "red" }}>Rimuovi</Button>}
            disableReordering={true}
            disableClear={true}
          >
            <ReferenceInput reference="website" source="website_id">
              <SelectInput optionText="sito" />
            </ReferenceInput>
            <NumberInput source="numero_articoli" defaultValue={0} min={0} />
            <NumberInput source="backlog" defaultValue={0} min={0} />
          </SimpleFormIterator>
        </ArrayInput>
      </SimpleForm>
    </Edit>
  </PaperWithTitle>
);
