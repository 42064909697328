import {
  ArrayField,
  ChipField,
  DateField,
  EmailField,
  NumberField,
  Show,
  SingleFieldList,
  TextField,
  useRecordContext,
  useRefresh,
  useUpdate,
} from "react-admin";
import {
  AdminIconField,
  EnabledIconField,
  InternalLinkField,
  PaperWithTitle,
  PaperWithTitleNoPadding,
  StatoField,
  StyledDatagrid,
} from "../layout/CustomComponents";
import WarningIcon from "@mui/icons-material/Warning";
import {
  Box,
  Button,
  Chip,
  Grid,
  Grow,
  Stack,
  Typography,
} from "@mui/material";
import { ImpostazioniShowDefault } from "../impostazioni/ImpostazioneShow";
import { useState, useEffect, useRef } from "react";

const EmptyWebsiteAssegnati = () => (
  <Typography sx={{ p: 2 }}>
    <WarningIcon color="warning" /> Nessun Utente assegnato
  </Typography>
);

const EmptyTaskAssegnati = () => (
  <Typography sx={{ p: 2 }}>
    <WarningIcon color="warning" /> Nessun Task assegnato
  </Typography>
);

const ShowImpostazioniForUser = () => {
  const record = useRecordContext();
  return (
    <ImpostazioniShowDefault
      userId={record.id}
      collapsed={true}
      showNotifiche={false}
    />
  );
};

const ShowCategorieAssegnateForUser = () => {
  const record = useRecordContext();
  return record.categorie_assegnate_ids &&
    record.categorie_assegnate_ids.length > 0 ? (
    record.categorie_assegnate.map((item) => (
      <Chip label={item.categoria} sx={{ m: 0.5 }} />
    ))
  ) : (
    <Typography variant="body2">Nessuna categoria assegnata</Typography>
  );
};

const ShowArticoliAggiuntiviAssegnatiForUser = () => {
  const record = useRecordContext();
  const [categoria, setCategoria] = useState();
  const refresh = useRefresh();
  const dataFetchedRef = useRef(false);
  const needRefresh = useRef(false);

  const [completa, { isLoading, isSuccess }] = useUpdate(
    `utente/${record.id}/articolo-aggiuntivo`,
    {
      id: categoria,
      previousData: record,
    }
  );

  if (isSuccess && needRefresh.current) {
    refresh();
    needRefresh.current = false;
  }

  useEffect(() => {
    console.log(dataFetchedRef.current);
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    needRefresh.current = true;
    console.log("categoria ", categoria);
    if (categoria >= 0) completa();
  });

  const handleClick = (id) => {
    dataFetchedRef.current = false;
    setCategoria(id);
  };

  return (
    <>
      {record.articoli_aggiuntivi && record.articoli_aggiuntivi.length > 0 ? (
        record.articoli_aggiuntivi.map((item) => (
          <Chip
            label={item.categoria}
            sx={{ m: 0.5 }}
            color={
              item.id === record.articolo_aggiuntivo_id ? "info" : "default"
            }
            onClick={() => handleClick(item.id)}
          />
        ))
      ) : (
        <Typography variant="body1">Nessun articolo aggiuntivo</Typography>
      )}
      <Chip
        label="Nessun articolo"
        sx={{ m: 0.5 }}
        color={0 === record.articolo_aggiuntivo_id ? "info" : "default"}
        onClick={() => handleClick(0)}
      />
    </>
  );
};

const UtentePanel = () => {
  return (
    <PaperWithTitle title="Visualizza Utente">
      <Grid container spacing={2} sx={{ mb: 1 }}>
        <Grid item md={8} xs={12}>
          <Stack spacing={1}>
            <Typography variant="body1">Nome: </Typography>
            <span>
              <TextField source="nome" /> <TextField source="cognome" />
            </span>
          </Stack>
        </Grid>
        <Grid item md={4} xs={12}>
          <Stack spacing={1} direction="row" justifyContent="flex-end">
            <Typography variant="body1">Amministratore </Typography>
            <EnabledIconField source="admin" />
          </Stack>
        </Grid>
        <Grid item md={8} xs={12}>
          <Stack spacing={1}>
            <Typography variant="body1">Email: </Typography>
            <EmailField source="email" />
          </Stack>
        </Grid>
        <Grid item md={4} xs={12}>
          <Stack spacing={1} direction="row" justifyContent="flex-end">
            <Typography variant="body1">Abilitato </Typography>
            <span>
              <EnabledIconField source="abilitato" />
            </span>
          </Stack>
        </Grid>
        <Grid item md={12} xs={12}>
          <Stack spacing={1}>
            <Typography variant="body1">Categorie Assegnate: </Typography>
            <span>
              <ShowCategorieAssegnateForUser />
            </span>
          </Stack>
        </Grid>
        <Grid item md={4} xs={12}>
          <Stack spacing={1}>
            <Typography variant="body1">Data Creazione: </Typography>
            <DateField source="data_creazione" showTime />
          </Stack>
        </Grid>
        <Grid item md={4} xs={12}>
          <Stack spacing={1}>
            <Typography variant="body1">Ultimo aggiornamento: </Typography>
            <DateField source="data_aggiornamento" showTime />
          </Stack>
        </Grid>
        <Grid item md={4} xs={12}>
          <Stack spacing={1}>
            <Typography variant="body1">Ultimo Login: </Typography>
            <DateField source="data_ultimo_login" showTime />
          </Stack>
        </Grid>
      </Grid>
    </PaperWithTitle>
  );
};

const WebsiteAssegnatiPanel = () => {
  return (
    <PaperWithTitleNoPadding title="Website assegnati " collapsed={true}>
      <ArrayField source="ruoli" label={false}>
        <StyledDatagrid
          bulkActionButtons={false}
          empty={<EmptyWebsiteAssegnati />}
          sx={{ mt: 2 }}
          rowClick={(id, resource, record) => {
            return `/website/${record.website_id}/show`;
          }}
        >
          <TextField source="website" />
          <TextField source="ruolo" />
          <NumberField source="numero_articoli" textAlign="center" />
          <NumberField
            source="backlog"
            textAlign="center"
            title="backlog articoli"
          />
          <InternalLinkField
            resource="website"
            path="website_id"
            textAlign="right"
          />
        </StyledDatagrid>
      </ArrayField>
    </PaperWithTitleNoPadding>
  );
};

const TaskAssegnatiPanel = () => {
  return (
    <PaperWithTitleNoPadding title="Ultimi 10 Task Assegnati" collapsed={true}>
      <ArrayField source="tasks" sortable={false} label={false}>
        <StyledDatagrid
          bulkActionButtons={false}
          empty={<EmptyTaskAssegnati />}
          sx={{ mt: 2 }}
          rowClick={(id, resource, record) => {
            return `/task/${record.id}/show`;
          }}
        >
          <TextField source="articolo_url" />
          <DateField source="data_assegnazione" showTime />
          <DateField source="data_chiusura" showTime />
          <StatoField source="stato" />
          <InternalLinkField resource="task" path="id" textAlign="right" />
        </StyledDatagrid>
      </ArrayField>
    </PaperWithTitleNoPadding>
  );
};

const BacklogPanel = () => {
  const record = useRecordContext();
  return (
    <PaperWithTitleNoPadding title="Backlog Articoli">
      <Stack spacing={2} sx={{ p: 2 }} direction="row">
        <Typography variant="body1">Totale articoli da recuperare: </Typography>
        <TextField source="backlog_articoli" />
      </Stack>
    </PaperWithTitleNoPadding>
  );
};

export const UtenteShow = () => (
  <>
    <Show actions={false} emptyWhileLoading sx={{ m: 0 }}>
      <Grid container>
        <Grid item lg={8} xs={12}>
          <UtentePanel />
        </Grid>
        <Grid item lg={4} xs={12}>
          <BacklogPanel />
          <PaperWithTitleNoPadding title="Articolo aggiuntivo per Domani">
            <Stack spacing={2} sx={{ p: 2 }} direction="row">
              <span>
                <ShowArticoliAggiuntiviAssegnatiForUser />
              </span>
            </Stack>
          </PaperWithTitleNoPadding>
        </Grid>
        <Grid item xs={12} lg={6}>
          <WebsiteAssegnatiPanel />
        </Grid>
        <Grid item xs={12} lg={6}>
          <TaskAssegnatiPanel />
        </Grid>
      </Grid>
      <ShowImpostazioniForUser />
    </Show>
  </>
);
