import * as React from "react";
import { FC, createElement } from "react";
import { Card, Box, Typography, Divider } from "@mui/material";
import { Link, To } from "react-router-dom";
import { Stack } from "@mui/system";
import { Area, AreaChart, ResponsiveContainer, Tooltip } from "recharts";

const CardWithChart = (props) => {
  const { icon, title, subtitle, to, value, data } = props;

  return (
    // @ts-ignore
    <Card
      sx={{
        minHeight: 163,
        display: "flex",
        flexDirection: "column",
        flex: "1",
        "& a": {
          textDecoration: "none",
          color: "inherit",
        },
        borderRadius: "10px",
        m: 2,
      }}
      elevation={4}
    >
      <Link to={to}>
        <Box
          sx={{
            overflow: "inherit",
            padding: "1em",
            pb: "0",
            display: "flex",
            justifyContent: "space-between",

            "& .icon": {
              color: "primary.dark",
            },
          }}
        >
          <Box textAlign="left">
            <Stack direction="row">
              <Typography variant="h2" component="h2">
                {title}
              </Typography>
              <Typography variant="h2" sx={{ fontWeight: "400" }}>
                &nbsp;{"| "}
                {subtitle}
              </Typography>
            </Stack>
          </Box>
          <Box
            className="icon"
            sx={{
              backgroundColor: "rgba(140, 179, 105, 0.1)",
              color: "primary.dark",
              p: "0.7em",
              maxHeight: "4em",
              maxWidth: "4em",
              borderRadius: "10px",
            }}
          >
            <div>{icon}</div>
          </Box>
        </Box>
        <Stack sx={{ pl: "2em", position: "absolute", zIndex: 1 }}>
          <Typography variant="h2" fontSize="3em">
            {value || "0"}
          </Typography>
        </Stack>
        <ResponsiveContainer
          width="95%"
          height={80}
          className="chartInsideCard"
        >
          <AreaChart data={data}>
            <defs>
              <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                <stop offset="2%" stopColor="#82ca9d" stopOpacity={0.8} />
                <stop offset="80%" stopColor="#82ca9d" stopOpacity={0} />
              </linearGradient>
            </defs>
            <Area
              type="monotone"
              dataKey="count"
              stroke="#82ca9d"
              fillOpacity={1}
              fill="url(#colorPv)"
            />
          </AreaChart>
        </ResponsiveContainer>
      </Link>
    </Card>
  );
};

export default CardWithChart;
