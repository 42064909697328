import {
  Button,
  Link,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import * as React from "react";
import { useState } from "react";
import { useLogin, useNotify, required } from "react-admin";
import { PaperWithTitle, PaperWithTitleNoPadding } from "./CustomComponents";
import GoogleIcon from "@mui/icons-material/Google";
import { apiUrl } from "../dataProvider";

const CustomLoginPage = ({ theme }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const login = useLogin();
  const notify = useNotify();
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("md"));

  const handleSubmit = (e) => {
    e.preventDefault();
    login({ username, password })
      .then(
        (json) => {
          return json;
        },
        (err) => {
          console.log("error =", err);
          throw new Error(err);
        }
      )
      .catch((e) => console.log("err", e));
  };

  return (
    <Stack
      direction="column"
      justifyContent="center"
      alignItems="center"
      sx={{ mt: 8 }}
    >
      <PaperWithTitleNoPadding
        sx={{ width: isSmall ? "90%" : "50%", maxWidth: "400" }}
      >
        <Stack spacing={4} sx={{ p: 2 }} textAlign={"center"}>
          <Link href={apiUrl + "/login-oauth"} sx={{ textDecoration: "none" }}>
            <Button
              variant="contained"
              startIcon={<GoogleIcon color="info" />}
              size="large"
              color="success"
              sx={{ m: 2 }}
            >
              Continua con Google
            </Button>
          </Link>
          <Typography>Oppure continua con username/password</Typography>
          <form onSubmit={handleSubmit}>
            <Stack spacing={1} sx={{ p: 2 }} textAlign={"center"}>
              <TextField
                required
                id="username"
                name="username"
                type="email"
                label="Email"
                value={username}
                validate={[required()]}
                onChange={(e) => setUsername(e.target.value)}
              />

              <TextField
                required
                name="password"
                type="password"
                label="Password"
                value={password}
                validate={[required()]}
                onChange={(e) => setPassword(e.target.value)}
              />
              <Button
                onClick={handleSubmit}
                variant="contained"
                color="success"
                size="large"
              >
                Login
              </Button>
            </Stack>
          </form>
        </Stack>
      </PaperWithTitleNoPadding>
    </Stack>
  );
};

export default CustomLoginPage;
