import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import { EditGuesser } from "react-admin";
import { ListGuesser, ShowGuesser } from "react-admin/dist/cjs";
import TaskIcon from "@mui/icons-material/Task";
import { TaskList } from "./taskList";
import { TaskShow } from "./taskShow";
import { TaskCreate } from "./taskCreate";

export default {
  list: TaskList,
  show: TaskShow,
  icon: TaskIcon,
  create: TaskCreate,
};
