import {
  FunctionField,
  Link,
  RichTextField,
  SimpleShowLayout,
} from "react-admin/dist/cjs";
import Typography from "@mui/material/Typography";
import {
  ArrayField,
  Show,
  TextField,
  useRecordContext,
  useRefresh,
  useCreate,
  UrlField,
  ImageField,
} from "react-admin";
import {
  StyledDatagrid,
  PaperWithTitle,
  EnabledIconField,
  InternalLinkField,
  PaperWithTitleNoPadding,
  StatoField,
} from "../layout/CustomComponents";
import { Button, Grid } from "@mui/material";
import { Stack } from "@mui/system";
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from "@mui/lab";
import { useState, useEffect, useRef } from "react";
import { ModalImage } from "../layout/modalImage/ModalImage";

const CustomTaskButton = ({ articolo_id, articolo_url }) => {
  const record = useRecordContext();
  return (
    <Stack
      direction="row"
      justifyContent="center"
      alignItems="center"
      sx={{ m: 2 }}
    >
      <Button
        component={Link}
        to={{
          pathname: "/task/create",
        }}
        state={{
          record: {
            articolo_id: articolo_id,
            articolo_url: articolo_url,
            url: record.url,
            website: record.id,
          },
        }}
      >
        Crea Task
      </Button>
    </Stack>
  );
};

const PostPanel = ({ articolo_id, articolo_url }) => {
  const record = useRecordContext();
  const found = record.task.find(
    (element) => element.stato === "assegnato" || element.stato === "chiuso"
  );
  return (
    <>
      <Stack spacing={2} sx={{ p: 2 }}>
        <Typography variant="h3" sx={{ p: 1 }}>
          Task assegnati al sito {record.sito}
        </Typography>
        <ArrayField source="task" sortable={false} label={false}>
          <StyledDatagrid
            bulkActionButtons={false}
            rowClick={(id, resource, record) => {
              return `/task/${record.id}/show`;
            }}
          >
            <TextField source="utente" />
            <TextField source="data_assegnazione" />
            <TextField source="data_chiusura" />
            <StatoField source="stato" textAlign="center" />
            <InternalLinkField resource="task" path="id" textAlign="right" />
          </StyledDatagrid>
        </ArrayField>
      </Stack>
      {!found ? (
        <CustomTaskButton
          articolo_id={articolo_id}
          articolo_url={articolo_url}
        />
      ) : (
        <></>
      )}
    </>
  );
};

const ArrayWebsite = () => {
  const record = useRecordContext();
  return (
    <ArrayField source="websites" sortable={false} label={false}>
      <StyledDatagrid
        bulkActionButtons={false}
        rowClick={(id, resource, record) => {
          return `/website/${record.id}/show`;
        }}
        expand={<PostPanel articolo_id={record.id} articolo_url={record.url} />}
      >
        <TextField source="sito" />
        <EnabledIconField source="abilitato" textAlign="center" />
        <InternalLinkField resource="website" path="id" textAlign="right" />
      </StyledDatagrid>
    </ArrayField>
  );
};

const TimelineLog = () => {
  const record = useRecordContext();
  return (
    <PaperWithTitleNoPadding title="Timeline">
      <Timeline position="alternate">
        {record.logs &&
          record.logs.map((log) => {
            return (
              <TimelineItem>
                <TimelineOppositeContent color="text.secondary">
                  <Typography>{log.timestamp}</Typography>
                </TimelineOppositeContent>
                <TimelineSeparator>
                  <TimelineDot color="secondary" />
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>{log.log}</TimelineContent>
              </TimelineItem>
            );
          })}
      </Timeline>
    </PaperWithTitleNoPadding>
  );
};

export const PostShow = () => {
  return (
    <Show actions={false} emptyWhileLoading>
      <Grid container>
        <Grid item md={12} xl={12}>
          <PaperWithTitle title="Visualizza Articolo">
            <Stack spacing={1} sx={{ p: 4 }}>
              <Typography variant="body1">URL: </Typography>
              <UrlField source="url" target={"_blank"} />
              <span></span>
              <Typography variant="body1">Categoria: </Typography>
              <TextField source="categoria.categoria" label="Categoria" />
              <span></span>
              <Typography variant="body1">Priorità: </Typography>
              <FunctionField
                label=""
                render={(record) => (record.priorita ? "alta" : "normale")}
              />
              <span></span>
              <Typography variant="body1">Utente Preferito: </Typography>
              <TextField
                source="utente_preferito_nome"
                label="Utente"
                emptyText="Nessuno"
              />
            </Stack>
          </PaperWithTitle>
          <PaperWithTitleNoPadding title="Dettagli Articolo">
            <Stack spacing={1} sx={{ p: 4 }}>
              <span></span>
              <Typography variant="body1">Titolo da inserire: </Typography>
              <TextField source="titolo_futuro" label="Titolo da inserire" />
              <span></span>
              <Typography variant="body1">Annotazioni: </Typography>
              <TextField
                source="descrizione"
                label="Descrizione"
                component="p"
                sx={{ whiteSpace: "pre-wrap" }}
              />
            </Stack>
          </PaperWithTitleNoPadding>
          <PaperWithTitleNoPadding title="Immagini Correlate" collapsed={true}>
            <Stack spacing={1} sx={{ p: 4 }}>
              <ModalImage delete={true} upload={true} />
            </Stack>
          </PaperWithTitleNoPadding>
        </Grid>
        <Grid item xs={12}>
          <PaperWithTitleNoPadding title="Assegnato ai Siti">
            <ArrayWebsite />
          </PaperWithTitleNoPadding>
        </Grid>
      </Grid>
    </Show>
  );
};
