import * as React from "react";
import {
  AppBar,
  Logout,
  usePermissions,
  UserMenu,
  useTheme,
} from "react-admin";
import { Link } from "react-router-dom";
import {
  MenuItem,
  ListItemIcon,
  ListItemText,
  IconButton,
  Badge,
} from "@mui/material";

import SettingsIcon from "@mui/icons-material/Settings";
import { NotificationButton } from "./NotificationButton";

const ConfigurationMenu = React.forwardRef((props, ref) => {
  return (
    <MenuItem
      component={Link}
      // @ts-ignore
      {...props}
      to="/impostazioni"
    >
      <ListItemIcon>
        <SettingsIcon />
      </ListItemIcon>
      <ListItemText>Profilo Personale</ListItemText>
    </MenuItem>
  );
});
const CustomUserMenu = () => (
  <>
    <NotificationButton />
    <UserMenu>
      <ConfigurationMenu />
      <Logout />
    </UserMenu>
  </>
);

const CustomAppBar = (props) => {
  return (
    <AppBar
      {...props}
      elevation={1}
      userMenu={<CustomUserMenu />}
      color="inherit"
    ></AppBar>
  );
};

export default CustomAppBar;
