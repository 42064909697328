import { Grid, useTheme } from "@mui/material";
import { DateField, Error, Loading, TextField, useGetOne } from "react-admin";
import {
  PaperWithTitleNoPadding,
  stringToColour,
  StyledDatagrid,
} from "../layout/CustomComponents";
import CardWithIcon from "./cardWithIcon";
import TaskOutlinedIcon from "@mui/icons-material/TaskOutlined";
import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  PieChart,
  Pie,
  Cell,
} from "recharts";
import { useNavigate } from "react-router-dom";
import { stringify } from "query-string";

export const DashboardAdmin = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { data, isLoading, error } = useGetOne("stats", {
    id: "admin",
  });

  if (isLoading) return <Loading />;
  if (error) return <Error />;

  function navigateToArticoloByCategoria(id) {
    navigate({
      pathname: "/articolo",
      search: stringify({
        page: 1,
        filter: JSON.stringify({ categoria: id, stato: "disponibile" }),
      }),
    });
  }

  function navigateToArticoloByWebsite(id) {
    navigate({
      pathname: "/articolo",
      search: stringify({
        page: 1,
        filter: JSON.stringify({ sito: id }),
      }),
    });
  }

  function navigateToTask(data, index) {
    navigate({
      pathname: "/task",
      search: stringify({
        page: 1,
        filter: JSON.stringify({ utente: data.id_utente, stato: "assegnato" }),
      }),
    });
  }

  return (
    <>
      <Grid container>
        <Grid item xl={3} md={6} xs={12}>
          <CardWithIcon
            icon={<TaskOutlinedIcon fontSize="large" />}
            title="Articoli"
            subtitle="Totali"
            value={data.totale_articoli.totale_articoli}
            to="/task"
            note="Totale articoli completati, assegnati e in attesa"
          ></CardWithIcon>
        </Grid>
        <Grid item xl={3} md={6} xs={12}>
          <CardWithIcon
            icon={<TaskOutlinedIcon fontSize="large" />}
            title="Articoli"
            subtitle="Completati"
            value={
              data.articoli_completati.totale_articoli_assegnati_completati
            }
            to="/task"
            note="Articoli assegnati e completati oggi"
          ></CardWithIcon>
        </Grid>
        <Grid item xl={3} md={6} xs={12}>
          <CardWithIcon
            icon={<TaskOutlinedIcon fontSize="large" />}
            title="Articoli"
            subtitle="Assegnati"
            value={
              data.articoli_assegnati_non_completati
                .totale_articoli_assegnati_noncompletati
            }
            to="/task"
            note="Articoli assegnati oggi non ancora completati"
          ></CardWithIcon>
        </Grid>
        <Grid item xl={3} md={6} xs={12}>
          <CardWithIcon
            icon={<TaskOutlinedIcon fontSize="large" />}
            title="Articoli"
            subtitle="Rimanenti"
            value={data.articoli_rimanenti.totale_articoli_rimanenti}
            to="/task"
            note="Articoli in attesa di essere assegnati"
          ></CardWithIcon>
        </Grid>
        <Grid item xl={4} md={6} xs={12}>
          <PaperWithTitleNoPadding title="Articoli da assegnare per Sito">
            <ResponsiveContainer
              width="95%"
              height={250}
              className="chartInsideCard"
            >
              <BarChart data={data.articoli_rimanenti.website}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="id" />
                <YAxis
                  yAxisId="left"
                  orientation="left"
                  stroke={theme.palette.secondary.light}
                />
                <YAxis
                  yAxisId="right"
                  orientation="right"
                  stroke={theme.palette.error.light}
                />
                <Tooltip />
                <Bar
                  yAxisId="left"
                  dataKey="count"
                  name="Articoli rimanenti"
                  fill={theme.palette.secondary.light}
                />
                <Bar
                  yAxisId="right"
                  dataKey="giorni"
                  name="Giorni rimanenti"
                  fill={theme.palette.error.light}
                />
                <Legend />
              </BarChart>
            </ResponsiveContainer>
          </PaperWithTitleNoPadding>
        </Grid>
        <Grid item xl={4} md={6} xs={12}>
          <PaperWithTitleNoPadding title="Assegnati non completati per Sito">
            <ResponsiveContainer
              width="95%"
              height={250}
              className="chartInsideCard"
            >
              <BarChart data={data.articoli_assegnati_non_completati.website}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="id" />
                <YAxis />
                <Tooltip />
                <Bar dataKey="count" fill={theme.palette.warning.light} />
              </BarChart>
            </ResponsiveContainer>
          </PaperWithTitleNoPadding>
        </Grid>
        <Grid item xl={4} md={6} xs={12}>
          <PaperWithTitleNoPadding title="Assegnati non completati per Utente">
            <ResponsiveContainer width="95%" height={250}>
              <BarChart data={data.articoli_assegnati_non_completati.utente}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis type="category" dataKey="id" />
                <YAxis type="number" />
                <Tooltip />
                <Bar
                  dataKey="count"
                  fill={theme.palette.warning.main}
                  name="Assegnati oggi"
                  onClick={navigateToTask}
                >
                  {data.articoli_assegnati_non_completati.utente.map(
                    (entry, index) => (
                      <Cell cursor="pointer" key={`cell-${index}`} />
                    )
                  )}
                </Bar>
                <Bar
                  dataKey="count_old"
                  fill={theme.palette.error.main}
                  name="Più vecchi di oggi"
                  onClick={navigateToTask}
                >
                  {data.articoli_assegnati_non_completati.utente.map(
                    (entry, index) => (
                      <Cell cursor="pointer" key={`cell-${index}`} />
                    )
                  )}
                </Bar>
                <Legend />
              </BarChart>
            </ResponsiveContainer>
          </PaperWithTitleNoPadding>
        </Grid>
        <Grid item xl={4} md={6} xs={12}>
          <PaperWithTitleNoPadding title="Prossimi Giorni OFF">
            <StyledDatagrid
              data={data.ferie_schedulate.ferie}
              isLoading={isLoading}
              sort={false}
              bulkActionButtons={false}
              rowClick={(id, resource, record) => {
                return `/utente/${record.id_utente}/show`;
              }}
            >
              <TextField source="nome"></TextField>
              <DateField source="data" label="Giorni OFF" textAlign="center" />
            </StyledDatagrid>
          </PaperWithTitleNoPadding>
        </Grid>
        <Grid item xl={4} md={6} xs={12}>
          <PaperWithTitleNoPadding title="Rimanenti per Categoria">
            <ResponsiveContainer width="95%" height={250}>
              <PieChart>
                <Pie
                  data={data.articoli_rimanenti_per_categoria}
                  dataKey="count"
                  nameKey="categoria"
                  fill="#82ca9d"
                  label
                >
                  {data.articoli_rimanenti_per_categoria.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={stringToColour(entry.categoria)}
                      onClick={(e) => navigateToArticoloByCategoria(entry.id)}
                    />
                  ))}
                </Pie>
                <Legend />
              </PieChart>
            </ResponsiveContainer>
          </PaperWithTitleNoPadding>
        </Grid>
        <Grid item xl={4} md={6} xs={12}>
          <PaperWithTitleNoPadding title="Articoli Previsti per Domani">
            <StyledDatagrid
              data={data.articoli_previsti}
              isLoading={isLoading}
              sort={false}
              bulkActionButtons={false}
              rowClick={(id, resource, record) => {
                navigateToArticoloByWebsite(record.id);
              }}
            >
              <TextField source="sito"></TextField>
              <TextField source="count" label="Numero" textAlign="center" />
            </StyledDatagrid>
          </PaperWithTitleNoPadding>
        </Grid>
      </Grid>
    </>
  );
};
