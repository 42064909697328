import {
  Admin,
  Resource,
  ListGuesser,
  ShowGuesser,
  CustomRoutes,
} from "react-admin";

import jsonServerProvider from "ra-data-json-server";
import posts from "./components/posts";
import { EditGuesser } from "react-admin/dist/cjs";
import PostIcon from "@mui/icons-material/Book";
import UserIcon from "@mui/icons-material/Group";

import { authProvider } from "./components/auth/authProvider";

import { themeOptions } from "./components/layout/Theme";
import Layout from "./components/layout/Layout";
import { dataProvider } from "./components/dataProvider";
import website from "./components/website";
import utente from "./components/utente";
import task from "./components/task";
import customLoginPage from "./components/layout/customLoginPage";
import { DashboardRoute } from "./components/dashboard/dashboardRoute";
import { Route } from "react-router-dom";
import { ImpostazioniShow } from "./components/impostazioni/ImpostazioneShow";

const App = () => (
  <Admin
    dataProvider={dataProvider}
    dashboard={DashboardRoute}
    authProvider={authProvider}
    loginPage={customLoginPage}
    theme={themeOptions}
    layout={Layout}
    requireAuth
    disableTelemetry={true}
  >
    <Resource name="articolo" {...posts} />
    <Resource name="website" {...website} />
    <Resource name="utente" {...utente} />
    <Resource name="task" {...task} />
    <CustomRoutes>
      <Route path="/impostazioni" element={<ImpostazioniShow />} />
    </CustomRoutes>
  </Admin>
);

export default App;
