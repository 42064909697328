import { Avatar } from "@mui/material";
import { Stack } from "@mui/system";
import { useState } from "react";
import {
  ChipField,
  DateField,
  FilterButton,
  FunctionField,
  List,
  Pagination,
  ReferenceInput,
  SelectInput,
  TextField,
  TextInput,
  TopToolbar,
  useGetList,
} from "react-admin";
import { StyledDatagrid } from "../layout/CustomComponents";

const postFilters = [
  <ReferenceInput source="utente" label="Utente" reference="utente">
    <SelectInput optionText="email" />
  </ReferenceInput>,
];

const NotificheListAction = () => (
  <TopToolbar>
    <FilterButton className="customButton" label="Aggiungi Filtro" />
  </TopToolbar>
);

export const NotificheShow = ({ isAdmin }) => {
  return (
    <Stack>
      <List
        resource="notifiche"
        filters={isAdmin && postFilters}
        actions={isAdmin && <NotificheListAction />}
      >
        <StyledDatagrid size="small" bulkActionButtons={false}>
          <FunctionField
            label="Nome"
            render={(record) => `${record.nome} ${record.cognome}`}
          />
          <TextField source="timestamp" label="Data" textAlign="center" />
          <TextField source="testo" />
        </StyledDatagrid>
      </List>
    </Stack>
  );
};
