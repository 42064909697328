import {
  EditButton,
  TextField,
  UrlField,
  TopToolbar,
  FunctionField,
  Show,
  SimpleShowLayout,
  ArrayField,
  NumberField,
  WrapperField,
  ListButton,
  DeleteButton,
  useRecordContext,
} from "react-admin";
import Typography from "@mui/material/Typography";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import WarningIcon from "@mui/icons-material/Warning";

import {
  EnabledIconField,
  InternalLinkField,
  PaperWithTitle,
  PaperWithTitleNoPadding,
  StyledDatagrid,
} from "../layout/CustomComponents";
import { Grid, Stack } from "@mui/material";

const EmptyUtentiAssegnati = () => (
  <Typography sx={{ p: 2 }}>
    <WarningIcon color="warning" /> Nessun Utente assegnato
  </Typography>
);

const WebsiteTitle = () => {
  const record = useRecordContext();
  return <span>Visualizza Sito {record ? `"${record.url}"` : ""}</span>;
};

export const WebsiteShow = () => (
  <Show actions={false} emptyWhileLoading title={<WebsiteTitle />}>
    <PaperWithTitle title="Visualizza Sito Gestito">
      <Grid container>
        <Grid item md={8}>
          <Stack spacing={1}>
            <Typography variant="body1">Nome del sito: </Typography>
            <TextField source="sito" />
            <Typography variant="body1">URL: </Typography>
            <UrlField source="url" />
          </Stack>
        </Grid>
        <Grid item md={4}>
          <Stack spacing={1}>
            <Typography variant="body1">Abilitato: </Typography>
            <EnabledIconField source="abilitato" />
            <span></span>
            <Typography variant="body1">Programmazione nel Weekend</Typography>
            <EnabledIconField source="weekend" />
            <span></span>
            <Typography variant="body1">Numero Articoli: </Typography>
            <span>
              <NumberField source="numero_articoli" />
            </span>
          </Stack>
        </Grid>
      </Grid>
    </PaperWithTitle>
    <PaperWithTitleNoPadding title="Utenti assegnati al sito">
      <ArrayField source="ruoli" sortable={false} label={false}>
        <StyledDatagrid
          bulkActionButtons={false}
          empty={<EmptyUtentiAssegnati />}
          rowClick={(id, resource, record) => {
            return `/utente/${record.utente.id}/show`;
          }}
        >
          <WrapperField label="Utente">
            <TextField source="utente.nome" />
            &nbsp;
            <TextField source="utente.cognome" />
          </WrapperField>
          <TextField source="ruolo" />
          <NumberField source="numero_articoli" textAlign="center" />
          <FunctionField
            textAlign="center"
            source="abilitato"
            sx={{ fontSize: "20px", maxWidth: "100px" }}
            render={(record) => {
              return record.utente.abilitato ? (
                <CheckCircleIcon sx={{ color: "green" }} />
              ) : (
                <CheckCircleOutlineIcon sx={{ color: "grey" }} />
              );
            }}
          />
          <InternalLinkField
            resource="utente"
            path="utente.id"
            textAlign="right"
          />
        </StyledDatagrid>
      </ArrayField>
    </PaperWithTitleNoPadding>
  </Show>
);
