import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";
import { fetchUtils, useNotify, useRefresh } from "react-admin";
import { apiUrl } from "../dataProvider";
import { NotificationShow } from "../layout/NotificationButton";

export const authProvider = {
  // called when the user attempts to log in
  login: ({ username, password }) => {
    fetchUtils
      .fetchJson(`${apiUrl}/login`, {
        method: "POST",
        body: JSON.stringify({ username, password }),
      })
      .then(({ json }) => {
        // save jwt
        localStorage.setItem("jwt", json.access_token);
        window.location = "/#";
      })
      .catch(() => {
        throw new Error("Username o password errata");
      });
    return Promise.resolve({ redirectTo: false });
  },
  // called when the user clicks on the logout button
  logout: () => {
    localStorage.removeItem("jwt");
    return Promise.resolve();
  },
  // called when the API returns an error
  checkError: ({ status }) => {
    if (status === 401 || status === 403) {
      localStorage.removeItem("jwt");
      return Promise.reject({ redirectTo: "/login" });
    }
    return Promise.resolve();
  },
  // called when the user navigates to a new location, to check for authentication
  checkAuth: () => {
    return localStorage.getItem("jwt") ? Promise.resolve() : Promise.reject();
  },
  // called when the user navigates to a new location, to check for permissions / roles
  getPermissions: () => {
    // check cookie for google login
    // change from cookie to localstorage
    let jwt = Cookies.get("jwt");
    if (jwt) {
      localStorage.setItem("jwt", jwt);
      Cookies.remove("jwt");
    } else {
      jwt = localStorage.getItem("jwt");
    }
    const tokenPayload = jwt_decode(jwt);
    let admin = tokenPayload.admin;
    return admin ? Promise.resolve(admin) : Promise.reject();
  },
  // get token
  getToken: () => {
    return localStorage.getItem("jwt");
  },
  getIdentity: () => {
    try {
      const tokenPayload = jwt_decode(localStorage.getItem("jwt"));
      let id = tokenPayload.sub;
      let fullName = tokenPayload.fullName;
      let avatar = tokenPayload.picture ? tokenPayload.picture : null;
      return Promise.resolve({ id, fullName, avatar });
    } catch (error) {
      return Promise.reject(error);
    }
  },
};
