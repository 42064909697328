import { Button, Grid, Link, TextField, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { DatePicker } from "@mui/x-date-pickers";

import dayjs from "dayjs";
import "dayjs/locale/it";
import { useState } from "react";
import { Confirm, Loading, useCreate, usePermissions } from "react-admin";

import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { apiUrl } from "../dataProvider";

export const CustomDownloadReportButton = ({ userId }) => {
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(dayjs(new Date()));
  const { permissions } = usePermissions();
  const [create, { data, isLoading, isSuccess }] = useCreate();
  console.log("CustomDownloadReportButton", userId);
  const handleClick = () => {
    setOpen(true);
  };
  const handleDialogClose = () => setOpen(false);
  const handleConfirm = () => {
    !permissions
      ? create(`report/mensile/${value.year()}/${value.month() + 1}`)
      : create(
          `utente/${userId}/report/mensile/${value.year()}/${value.month() + 1}`
        );
    setOpen(false);
  };

  if (isLoading)
    return <Loading loadingSecondary="Creazione report in corso" />;

  return (
    <Grid container sx={{ p: 2 }}>
      <Grid item xs={8} sx={{ p: 1 }}>
        <DatePicker
          views={["year", "month"]}
          label="Mese e Anno"
          minDate={dayjs("2023-01-01")}
          maxDate={dayjs("2032-01-01")}
          value={value}
          disableFuture={true}
          onChange={(newValue) => {
            setValue(newValue);
          }}
          renderInput={(params) => (
            <TextField {...params} helperText={null} fullWidth />
          )}
        />
      </Grid>
      <Grid item xs={4}>
        <Button
          color="white"
          className="completaButton"
          onClick={() => handleClick()}
          disabled={isLoading}
          sx={{ p: 1, m: 1 }}
        >
          Genera Report
        </Button>
      </Grid>
      <Grid item xs={12}>
        {isSuccess ? (
          <Link
            href={`${apiUrl}/download/report/${data.id}`}
            target="_blank"
            sx={{ p: 1 }}
          >
            <FileDownloadIcon /> {data.id}
          </Link>
        ) : (
          <></>
        )}
      </Grid>
      <Confirm
        isOpen={open}
        loading={isLoading}
        title="Download report"
        content={
          "Sei sicuro di volere scaricare il report per " +
          value.format("MMMM YYYY") +
          " ?"
        }
        onConfirm={() => handleConfirm()}
        confirm="SI"
        cancel="NO"
        onClose={handleDialogClose}
      />
    </Grid>
  );
};
